import { Component, Input } from '@angular/core';

@Component({
  selector: 'project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.css'],
})

export class ProjectHeaderComponent {
  @Input() projTitle: string;
  @Input() projDesc: string;

  constructor() { }


}
