import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {
  featureProjects = [
    {
      name: "PopWatch",
      desc: "An app that personalizes movie selection and makes it social.",
      imgDest: "./assets/Images/popwatch-cover.jpg",
      routerDest: 'proj-popwatch'
    }
  ]

  otherProjects = [
    {
      name: "Requirements Manager",
      desc: "A web app used to manage student software requirements gathering projects.",
      imgDest: "./assets/Images/req-mngr-cover.jpg",
      routerDest: 'proj-req-mngr'
    },
    {
      name: "Fitness Tracker",
      desc: "A web app that makes scheduling workouts and tracking fitness easy for workout enthusiasts.",
      imgDest: "./assets/Images/fitness-tracker-cover-3-01.jpg",
      routerDest: 'proj-fitness-tracker'
    }
  ]

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
  }

  goTo(link: string){
    this.router.navigate([link]);
  }

}
