import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'featured-projects',
  templateUrl: './featured-projects.component.html',
  styleUrls: ['./featured-projects.component.css']
})
export class FeaturedProjectsComponent implements OnInit {
  featProjs = ["popwatch"];

  constructor() { }

  ngOnInit() {
  }

}
