import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'requirements-manager',
  templateUrl: './requirements-manager.component.html',
  styleUrls: ['./requirements-manager.component.css']
})
export class RequirementsManagerComponent implements OnInit {
  challenge = "A professor approached me about a system she was building for a class. The class focused on software requirements gathering, where students work in teams on a term long project that requires different types of deliverables. The goal of the system was to make project tracking and grading easier for students, professors, and teacher assistants. The system and UI was already built out when I started working on this project, however, the professor thought it was cluttered and required too many clicks. Students thought it was nice, but required more time to complete deliverables compared to using GoogleDocs.";
  solution = "I first approached the problem by talking to students and professors in order to gain better insight on their behaviors and frustrations. I spent a lot of time discussing the professor’s vision for the system, and how she imagined using it. We started by working on the main page where users could view their projects. I focused on creating a hierarchy on this page, where projects were grouped and labeled in order to help users find what they are looking for easily.";
  timeline = "Winter 2018-Present";
  info = "Computer Science & Software Engineering Departmental Improvement Opportunity";
  role = "Redesigned existing interface and developed UI standards";
  tools = "AdobeXD, Zeplin";

  constructor() { }

  ngOnInit() {
  }

}
