import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fitness-tracker',
  templateUrl: './fitness-tracker.component.html',
  styleUrls: ['./fitness-tracker.component.css']
})
export class FitnessTrackerComponent implements OnInit {
  challenge = "Many people decide to start working out at one point or another, however, knowing where to start can be difficult to determine. After talking to some workout enthusiasts, I found that people new to fitness struggle with determining workout schedules and nutritional needs. There are currently many apps and devices that help people track fitness, but not many that provide fitness suggestions based on someone’s workout schedule.";
  solution = "My solution is a fitness tracker that allows easy workout schedule creation and makes daily pre and post workout recommendations. I found there was a lot of variation in logging workouts while interviewing potential users, and decided to focus on the scheduling component design.";
  timeline = "3 weeks";
  info = "Individual Design Project";
  role = "Formed product concept, interviewed and surveyed potential users, created wireframes and prototype";
  tools = "AdobeXD";

  constructor() { }

  ngOnInit() {
  }

}
