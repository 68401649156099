import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { AboutComponent } from './components/about/about.component';
import { PopwatchComponent } from './components/projects/popwatch/popwatch.component';
import { FitnessTrackerComponent } from './components/projects/fitness-tracker/fitness-tracker.component';
import { RequirementsManagerComponent } from './components/projects/requirements-manager/requirements-manager.component';

const routes: Routes = [
  { path: 'portfolio', component: PortfolioComponent },
  { path: 'about', component: AboutComponent},
  { path: 'proj-popwatch', component: PopwatchComponent},
  { path: 'proj-fitness-tracker', component: FitnessTrackerComponent},
  { path: 'proj-req-mngr', component: RequirementsManagerComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
