import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  projects= [
    {
      name: "Popwatch",
      routerDest: 'proj-popwatch'
    },
    {
      name: "Requirements Manager",
      routerDest: 'proj-req-mngr'
    },
    {
      name: "Fitness Tracker",
      routerDest: 'proj-fitness-tracker'
    }
  ];

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
  }
  
  goTo(link: string){
    this.router.navigate([link]);
    console.log(this.router.getCurrentNavigation());
  }

}
