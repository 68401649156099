import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'popwatch',
  templateUrl: './popwatch.component.html',
  styleUrls: ['./popwatch.component.css']
})
export class PopwatchComponent implements OnInit {
  challenge = "A lot of stress and wasted time revolves around the question of, “What movie are we going to watch tonight?”. There are currently a lot of movie rating and streaming sites, like Netflix and IMDB, that offer a large catalog of movies for people to search through; however, this search process requires the user to know what they want in order to filter and limit their options.";
  solution = "My solution is PopWatch, an app that personalizes movie selection and makes it social. I found that people discover movies to watch either online or through friends. PopWatch is a place where movie watchers can utilize both those resources by friending people with similar tastes, and receiving endless movie recommendations based on their activity. It aims to help people receive and share movie recommendations and get to the watching part faster.";
  timeline = "3 weeks";
  info = "Individual Design Project";
  role = "Formed a product concept, surveyed potential users, created wireframes and a prototype";
  tools = "AdobeXD";

  features = [
    "Login",
    "Search for movies and friends",
    "Track movies through 'To Watch', 'Seen', and 'Liked' lists",
    "View user profile",
    "Add and request friends",
    "View movie page",
    "Recommend movies to friends",
    "Review movies",
    "Message friends"
  ];

  personas = [
    {
      name: "The Social Watcher",
      desc: "Occasionally watches movies in a group setting • Only likes certain kinds of movies • Comfortable using a mobile device • Uses Netflix for movie recommendations",
      allFrusts: [
        "Spends a lot of time choosing a movie",
        "Finds it difficult to choose a movie everyone will like",
        "Sometimes uncertain about whether they will enjoy a movie or not",
        "Doesn’t always know what movies are out"
      ],
      imgDest: "./assets/Images/user-22.png"
    },
    {
      name: "The Family Movie Night Watcher",
      desc: "Rarely watches movies on their own • Usually watches with family and friends spontaneously • Only likes certain kinds of movies • Likes watching older movies",
      allFrusts: [
        "Spends a lot of time searching for movies",
        "Finds it difficult to choose a movie everyone will like"
      ],
      imgDest: "./assets/Images/user-4.png"
    },
    {
      name: "The Movie Buff",
      desc: "Avid movie watcher • Likes passing the time with a movie • Usually watches movies alone • Likes to explore and watch new movies • Likes movies from all types of genres • Comfortable using a mobile device",
      allFrusts: [
        "Has seen many of the movies that are usually recommended",
        "Doesn't like to search through multiple movie platforms (Hulu, Netflix, Amazon Prime)"
      ],
      imgDest: "./assets/Images/user-2.png"
    },
    {
      name: "The Info Seeker",
      desc: "Occasional movie watcher • Enjoys learning more about movie cast and info • Tries to use phone as little as possible",
      allFrusts: [
        "Finds most apps convoluted with information"
      ],
      imgDest: "./assets/Images/user-8.png"
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
