import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'project-overview',
  templateUrl: './project-overview.component.html',
  styleUrls: ['./project-overview.component.css']
})
export class ProjectOverviewComponent implements OnInit {
  @Input() chal: string;
  @Input() sol: string;
  @Input() time: string;
  @Input() info: string;
  @Input() role: string;
  @Input() tools: string;

  constructor() { }

  ngOnInit() {
  }

}
