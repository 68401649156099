import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {trigger, state, style, animate, transition} from '@angular/animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'abc-web';
  link = ['portfolio'];
  showResMenu = false;

  constructor(
    private router: Router) {
    }

  ngOnInit(): void {
    this.router.navigate(this.link);
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
  });
  }

  goToLink(link : string){
    this.link = [link];
    this.router.navigate(this.link);
    this.showResMenu = false;
  }

  isSelected(name : string){
    if(name === this.link[0]){
      return true;
    }
    else {
      return false;
    }
  }

  showResponsiveMenu(){
    this.showResMenu = !this.showResMenu;
  }

}
