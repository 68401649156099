import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'large-card',
  templateUrl: './large-card.component.html',
  styleUrls: ['./large-card.component.css']
})
export class LargeCardComponent implements OnInit {
  imgDestination = "./assets/Images/test-image.jpg";
  title = "Title for the project";
  desc = "Description for project when hovered on asldfksa jd lkfd jsalk d jf sdlkjfsjd";

  constructor() { }

  ngOnInit():void {
  }

}
