import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { CardComponent } from './shared/card/card.component';
import { BookComponent } from './shared/book/book.component';
import { LargeCardComponent } from './shared/large-card/large-card.component';
import { SmallCardComponent } from './shared/small-card/small-card.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { AboutComponent } from './components/about/about.component';
import { PopwatchComponent } from './components/projects/popwatch/popwatch.component';
import { FitnessTrackerComponent } from './components/projects/fitness-tracker/fitness-tracker.component';
import { RequirementsManagerComponent } from './components/projects/requirements-manager/requirements-manager.component';
import { FeaturedProjectsComponent } from './shared/featured-projects/featured-projects.component';
import { ProjectHeaderComponent } from './shared/project-header/project-header.component';
import { ProjectOverviewComponent } from './shared/project-overview/project-overview.component';
import { PersonaComponent } from './shared/persona/persona.component';
import { CalloutComponent } from './shared/callout/callout.component';
import { ScribbleListComponent } from './shared/scribble-list/scribble-list.component';
import { FooterComponent } from './shared/footer/footer.component';

import { ResMenuComponent } from './components/res-menu/res-menu.component';

import { AppRoutingModule } from './app-routing.module';

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    BookComponent,
    LargeCardComponent,
    SmallCardComponent,
    PortfolioComponent,
    AboutComponent,
    FeaturedProjectsComponent,
    PopwatchComponent,
    FitnessTrackerComponent,
    RequirementsManagerComponent,
    ProjectHeaderComponent,
    ProjectOverviewComponent,
    PersonaComponent,
    CalloutComponent,
    ScribbleListComponent,
    FooterComponent,
    ResMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
