import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() projTitle: string;
  @Input() projDesc: string;
  @Input() imgDestination: string;

  constructor() { }

  ngOnInit():void {
    if(this.imgDestination === ""){
      this.imgDestination = "./assets/Images/test-image.jpg";
    }
    if(this.projTitle === ""){
      this.projTitle = "Title for the project";
    }
    if(this.projDesc === ""){
      this.projDesc = "Description for project when hovered on asldfksa jd lkfd jsalk d jf sdlkjfsjd";
    }
  }

}
