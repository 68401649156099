import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'small-card',
  templateUrl: './small-card.component.html',
  styleUrls: ['./small-card.component.css']
})
export class SmallCardComponent implements OnInit {
  imgDestination = "./assets/Images/test-image.jpg";
  title = "Title for the project";
  desc = "Description for project when hovered on asldfksa jd lkfd jsalk d jf sdlkjfsjd";

  constructor() { }

  ngOnInit() {
  }

}
