import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'scribble-list',
  templateUrl: './scribble-list.component.html',
  styleUrls: ['./scribble-list.component.css']
})
export class ScribbleListComponent implements OnInit {
  @Input() title: string;
  @Input() items: any[];

  constructor() { }

  ngOnInit() {
    console.log(this.items);
  }

}
