import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'persona',
  templateUrl: './persona.component.html',
  styleUrls: ['./persona.component.css']
})
export class PersonaComponent implements OnInit {
  @Input() name: string;
  @Input() desc: string;
  @Input() allFrusts: any[];
  @Input() img: string;

  constructor() { }

  ngOnInit() {
  }

}
